import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  interval,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { ApiService } from '@shared/api/api.service';
import { BaseObject } from '@shared/base/base-object';
import { isNotNull } from '@shared/base/core';
import { UserState } from '@shared/states/user.state';
import { Complex } from '@shared/types/living.types';

export interface City {
  id: number;
  title: string;
}

export interface OrderContainer {
  order: Order;
  projects: Project[];
}

export interface Order {
  orderId: number;
  managerId: number;
  living_object_id: string;
  configId: number;
  city: City;
  jk: Complex;
  apart_param: {
    [key: string]: string | number;
  };
}

export interface Project {
  id: number;
  repair_type_id: number;
  material_level_id: number;
  brigade_level_id: number;
  options: Record<string, string | number>;
  side_info: {
    calculate: ProjectCalculate;
  };
  template: ProjectTemplate;
  templateId: number;
  is_active: boolean;
  is_self: boolean;
  is_recount: boolean;
  repairType: ProjectRepairType;
  materialLevel: CatalogObject;
  brigadeLevel: CatalogObject;
}

export type ProjectTemplate = Pick<Template, 'id' | 'name' | 'color' | 'sort'>;

export enum CostColorEnum {
  Red = 'Red',
  Blue = 'Blue',
  Purple = 'Purple',
}

export interface ProjectCalculate {
  total: number;
  works: number;
  materials: number;
  materialTotal: number;
  materialsFinishing: number;
}

export type ProjectRepairType = Pick<CatalogObject, 'id' | 'name' | 'description'>;

export interface Catalogs {
  repair: CatalogObject[];
  material: CatalogObject[];
  brigade: CatalogObject[];
  template: Template[];
}

export interface CatalogObject {
  id: number;
  name: string;
  description: string;
  key: string;
  is_active: boolean;
  price_category?: number;
  quality_category?: number;
  price_percent?: number;
  score?: string;
  user_question?: string;
}

export interface Template {
  id: number;
  name: string;
  color: CostColorEnum;
  created: string;
  deleted: boolean;
  is_active: boolean;
  is_default: boolean;
  key: string;
  repair_type_id: number;
  characters: Character[];
  sort: number;
  updated: string;
}

export interface Character {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderState extends BaseObject {
  public get order(): Order {
    return this.order$.value;
  }

  public order$ = new BehaviorSubject<Order>(null);
  public projects$ = new BehaviorSubject<Project[]>(null);
  public catalogs$ = new BehaviorSubject<Catalogs>(null);
  public activeProject$ = new BehaviorSubject<Project>(null);

  constructor(
    private apiService: ApiService,
    private userState: UserState,
  ) {
    super();

    combineLatest([this.userState.userIsLogin$, this.userState.user$])
      .pipe(
        filter(([userIsLogin, user]) => userIsLogin && !!user),
        first(),
        switchMap(() => {
          this.getCatalogList();

          return this.loadOrder();
        }),
        filter((result) => result.projects?.some((project) => project.is_recount)),
      )
      .subscribe(() => this.listenToProjectsRecalculation());
  }

  public loadOrder(): Observable<OrderContainer> {
    return this.apiService.getOrder().pipe(
      first(),
      filter((result) => !!result),
      tap((result) => {
        const activeProject = result.projects.find((project) => project.is_active);

        this.order$.next(result.order);
        this.projects$.next(result.projects);

        if (activeProject) {
          this.activeProject$.next(activeProject);
        }
      }),
    );
  }

  public getProjects(): Observable<Pick<OrderContainer, 'projects'>> {
    return this.apiService.getOrder(true).pipe(first());
  }

  private getCatalogList(): void {
    this.apiService
      .getCatalogList()
      .pipe(
        first(),
        filter((result) => !!result),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        this.catalogs$.next(result);
      });
  }

  public listenToProjectsRecalculation(): void {
    const activeProject = this.activeProject$.value;

    if (!activeProject) {
      return;
    }

    const stopListen$ = new Subject<void>();
    activeProject.is_recount = true;

    this.projects$.value?.forEach((project) => (project.is_recount = true));

    interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.getProjects()),
        takeUntil(stopListen$),
      )
      .subscribe((result) => {
        this.projects$.next(result.projects);

        const activeProject = result.projects.find((project) => project.is_active);
        const currentActiveProject = this.activeProject$.value;
        const total = activeProject.side_info?.calculate?.total;
        const currentTotal = currentActiveProject.side_info?.calculate?.total;

        if (
          activeProject.is_recount !== currentActiveProject.is_recount ||
          ((isNotNull(total) || isNotNull(currentTotal)) && total !== currentTotal)
        ) {
          this.activeProject$.next(activeProject);
        }

        let isRecount = activeProject.is_recount;

        if (!isRecount) {
          result.projects.forEach((project) => {
            if (project.is_recount) {
              isRecount = true;
            }
          });
        }

        if (!isRecount) {
          stopListen$.next();
        }
      });
  }
}
